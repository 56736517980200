.slider {
  color: $white;
  position: relative;

  a {
    color: $white;
  }
}

.slide {
  overflow: hidden;
  position: relative;

  @media screen and (max-width: $tablet-max) {
    @include flex-direction(column);
  }

  @media screen and (max-width: $large-mobile-max) {
    padding-bottom: 0;
  }
}

.slide__story {
  padding: $u-space-default 0 $u-space-tiny;

  @media screen and (min-width: $tablet-min) {
    padding: $u-space-default 0;
  }

  @media screen and (min-width: $small-desktop-min) {
    padding: $u-space-big $u-space-default $u-space-big 10vw;
  }

  @media screen and (min-width: $desktop-min) {
    padding: $u-space-big $u-space-default $u-space-big 15vw;
  }

  @media screen and (min-width: $large-desktop-min) {
    padding: $u-space-big $u-space-default $u-space-big 21vw;
  }
}

.slide__image {
  position: relative;

  @media screen and (min-width: $tablet-min) {
    overflow: hidden;
  }
}

.slide__image img {
  @media screen and (max-width: $large-mobile-max) {
    margin-left: -4vw;
    margin-top: 2.5rem;
    max-width: none;
    width: calc(100% + 8vw);
  }

  @media screen and (min-width: $small-desktop-min) {
    position: absolute;
    top: 50%;
    left: 50%;

    width: auto;
    height: auto;

    max-height: none;
    max-width: none;

    min-height: 100%;
    min-width: 100%;

    transform: translate(-50%, -50%);
  }
}

// Slider controls

.tns-controls {
  position: absolute;
  color: transparent;
  z-index: 1;

  @media screen and (min-width: $small-desktop-min) {
    top: 50%;
    right: 60px;
  }

  .slider--quote & {
    right: -5vw;
  }

  .slider--user-stories & {
    @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
      top: 85px;
      right: 5vw;
    }
  }
}

[data-controls="prev"] {
  display: none;
}

[data-controls="next"] {
  background: url("/assets/img/svg/Slider-Right.svg") center no-repeat;
  height: 40px;
  width: 40px;

  @media screen and (max-width: $large-mobile-max) {
    display: none;
  }
}

// Slider nav (dots)

.tns-nav {
  position: absolute;
  z-index: 1;
}

[data-nav] {
  border-radius: 50%;
  height: 8px;
  width: 8px;
  margin-right: 9px;

  &:not(.tns-nav-active) {
    background: $white;
  }
}

.tns-nav-active {
  background: transparent;
  border: 1px solid $white;
  position: relative;
  top: -1px;
}

// User Stories Slider

.slider--user-stories {
  min-height: 32rem;

  .tns-nav {
    left: 4vw;
    top: 2rem;

    @media screen and (min-width: $tablet-min) {
      left: 10vw;
    }

    @media screen and (min-width: $desktop-min) {
      left: 15vw;
    }

    @media screen and (min-width: $large-desktop-min) {
      left: 21vw;
    }
  }

  .split--50 {
    @media screen and (min-width: $small-desktop-min) {
      @include flex(0 0 50%);
    }
  }
}

// Quote Slider

.slider--quote {
  max-width: 100%;
  margin-bottom: $u-space-big;

  @media screen and (min-width: $tablet-min) {
    margin-bottom: $u-space-default;
    max-width: 60%;
  }

  .tns-nav {
    left: 0;
    bottom: -3rem;
  }
}
