.flipping-text {
  position: relative;
  overflow: hidden;
  height: 40px;

  &__list {
    text-align: left;

    -webkit-animation-name: change;
    -webkit-animation-duration: 10s;
    -webkit-animation-iteration-count: infinite;
    animation-name: change;
    animation-duration: 10s;
    animation-iteration-count: infinite;

    &__item {
      line-height:40px;
      margin:0;
      font-size: $base-font-size1;
      font-weight: bold;

      @media screen and (min-width: $small-desktop-min) {
        font-size: $base-font-size2;
      }
    }
  }
}

@keyframes change {
  0%, 22%, 100% {transform:translate3d(0,0,0);}
  25%, 47% {transform:translate3d(0,-33%,0);}
  50%, 72% {transform:translate3d(0,-66%,0);}
  75%, 97% {transform:translate3d(0,-33%,0);}
}
