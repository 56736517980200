picture {
  width: 100%; /* stretch to fit its containing element */
}

picture img {
  width: 100%; /* stretch to fill the picture element */
}

img.lazyloaded,
.panel--banner.lazyloaded,
.img-full-width.lazyloaded {
  transition: 0.5s filter;
}

img.lazyloading,
.panel--banner.lazyloading,
.img-full-width.lazyloading {
  filter: blur(20px);
}
