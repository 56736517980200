$header-link-color-gray: $gray;
$header-link-color-hm: $green;
$header-link-color-ac: $blue;
$header-link-color-white: $white;

// Logo themes

.header__logo .logo--hm,
.header__logo .logo--hm-vertical {
  fill: $gray;

  [data-current^="home"] &,
  [data-current^="airbeam"] &,
  [data-current^="donate"] & {
    fill: $green;
  }

  [data-current^="faq"] &,
  [data-current^="about"] &,
  [data-current^="blog"] & {
    fill: $white;
  }

  [data-current^="aircasting"] & {
    fill: $blue;
  }

  .sticky & {
    [data-current^="about"] & {
      fill: $teal;
    }

    [data-current^="blog"] & {
      fill: $header-link-color-ac;
    }

    [data-current^="faq"] & {
      fill: $header-link-color-hm;
    }
  }

  @media screen and (max-width: $tablet-max) {
    .header--nav-expanded & {
      fill: $white !important;
    }
  }
}

.logo--hm {
  width: 8rem;
  display: none;

  @media screen and (min-width: $small-desktop-min) {
    display: block;
  }
}

.logo--hm-vertical {
  margin-top: 3px;

  @media screen and (min-width: $small-desktop-min) {
    display: none;
  }
}

// Header background themes

.header {
  background-color: $teal-light;

  [data-current^="home"] &,
  [data-current^="airbeam"] &,
  [data-current^="aircasting"] &,
  [data-current^="donate"] & {
    background-color: $white;
  }

  [data-current^="faq"] & {
    background-color: $green;
  }

  [data-current^="about"] & {
    background-color: $teal;
  }

  [data-current^="blog"] & {
    background-color: $blue;
  }

  &.sticky,
  .sticky & {
    background-color: $white;
    z-index: 101;
  }

  @media screen and (max-width: $tablet-max) {
    &.header--nav-expanded,
    .header--nav-expanded & {
      background-color: $green;
    }
  }
}

// Main navigation links themes

.nav-list__link {
  color: $white;

  &[data-current^="current page"] {
    color: rgba($white, 0.55);
  }
}

// Subnavigation links themes

.subnav-list__link {
  color: $white;

  .header--nav-expanded & {
    &[data-current^="current page"],
    &:hover,
    &:focus {
      color: rgba($white, 0.55);
    }
  }
}

// Header Button themes

.header__button {
  @include button-color(
    $text-color: $header-link-color-gray,
    $border-color: $header-link-color-gray,
    $bg-color: $teal-light,
    $bg-color-hover: $header-link-color-gray
  );

  .header--nav-expanded .nav-sub > & {
    @include button-color(
      $text-color: $header-link-color-white,
      $border-color: $header-link-color-white,
      $bg-color: none,
      $bg-color-hover: $header-link-color-white,
      $text-color-hover: $green
    );
  }

  [data-current^="home"] & {
    @include button-color(
      $text-color: $gray-dark,
      $border-color: $cta-color,
      $bg-color: $cta-color,
      $bg-color-hover: rgba($cta-color, 0.75),
      $text-color-hover: $gray-dark
    );
  }

  [data-current^="airbeam"] &,
  [data-current^="donate"] & {
    @include button-color(
      $text-color: $header-link-color-hm,
      $border-color: $header-link-color-hm,
      $bg-color: $white,
      $bg-color-hover: $header-link-color-hm
    );
  }

  [data-current^="aircasting"] & {
    @include button-color(
      $text-color: $header-link-color-ac,
      $border-color: $header-link-color-ac,
      $bg-color: $white,
      $bg-color-hover: $header-link-color-ac
    );
  }

  [data-current^="about"] & {
    @include button-color(
      $text-color: $header-link-color-white,
      $border-color: $header-link-color-white,
      $bg-color: $teal,
      $bg-color-hover: $header-link-color-white
    );
  }

  [data-current^="faq"] & {
    @include button-color(
      $text-color: $header-link-color-white,
      $border-color: $header-link-color-white,
      $bg-color: $green,
      $bg-color-hover: $header-link-color-white
    );
  }

  [data-current^="blog"] & {
    @include button-color(
      $text-color: $header-link-color-white,
      $border-color: $header-link-color-white,
      $bg-color: $blue,
      $bg-color-hover: $header-link-color-white
    );
  }

  .sticky & {
    [data-current^="about"] & {
      @include button-color(
        $text-color: $teal,
        $border-color: $teal,
        $bg-color: $header-link-color-white,
        $bg-color-hover: $teal
      );
    }

    [data-current^="blog"] & {
      @include button-color(
        $text-color: $blue,
        $border-color: $blue,
        $bg-color: $header-link-color-white,
        $bg-color-hover: $blue
      );
    }

    [data-current^="faq"] & {
      @include button-color(
        $text-color: $green,
        $border-color: $green,
        $bg-color: $header-link-color-white,
        $bg-color-hover: $green
      );
    }
  }
}

// Icon for mobile nav themes

.icon-nav-open {
  stroke: $gray;

  [data-current^="airbeam"] &,
  [data-current^="donate"] & {
    stroke: $green;
  }

  [data-current^="faq"] &,
  [data-current^="about"] &,
  [data-current^="blog"] & {
    stroke: $white;
  }

  [data-current^="aircasting"] & {
    stroke: $blue;
  }

  .sticky & {
    [data-current^="about"] & {
      stroke: $teal;
    }

    [data-current^="blog"] & {
      stroke: $header-link-color-ac;
    }

    [data-current^="faq"] & {
      stroke: $header-link-color-hm;
    }
  }
}
