.split--65 {
  @media screen and (min-width: $tablet-min) {
    @include flex(0 0 65%);
  }
}

.split--60 {
  @media screen and (min-width: $tablet-min) {
    @include flex(0 0 60%);
  }
}

.split--50 {
  @media screen and (min-width: $tablet-min) {
    @include flex(0 0 50%);
  }
}

.split--40 {
  @media screen and (min-width: $tablet-min) {
    @include flex(0 0 40%);
  }
}

.split--30 {
  @media screen and (min-width: $tablet-min) {
    @include flex(0 0 30%);
  }
}

.split--25 {
  margin-bottom: $u-space-default;

  @media screen and (min-width: $tablet-min) {
    @include flex(0 0 48%);
    @include justify-content(space-between);
  }

  @media screen and (min-width: $small-desktop-min) {
    @include flex(0 0 21%);
    @include justify-content(space-between);
  }

  img {
    margin: 2rem auto;
    max-width: 13rem;
    width: 95%;
  }
}

.split--padding-right {
  @media screen and (max-width: $large-mobile-max) {
    padding-bottom: $u-space-small;

    &.split--order-secondary {
      padding-bottom: 0;
      padding-top: $u-space-small;
    }
  }

  @media screen and (min-width: $tablet-min) {
    padding-bottom: 0;
    padding-right: $u-space-small;
  }
}

.split--padding-left {
  @media screen and (min-width: $tablet-min) {
    padding-left: $u-space-small;
  }
}

.split--order-secondary {
  @media screen and (max-width: $large-mobile-max) {
    order: 2;
  }
}
