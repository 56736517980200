html {
  font-size: $base-font-size;
}

body {
  font-family: $body-font;
  color: $gray-medium;
}

.heading {
  font-family: $bold-font;
  line-height: 1.2;
}

.heading--conversion {
  font-weight: 400;
  font-size: 2.25rem;

  @media screen and (min-width: $tablet-min) {
    font-size: $base-font-size4;
  }
}

.heading--large {
  font-size: $base-font-size3;

  @media screen and (min-width: $tablet-min) {
    font-size: $base-font-size4;
  }
}

.heading--medium {
  font-size: $base-font-size3;
  margin: 0 0 $u-space-small;
}

.heading--small {
  font-size: $base-font-size1;
  line-height: 1.3;

  .read-more-button + & {
    margin-top: 1rem;
  }
}

.heading--body {
  margin-bottom: 1rem;
}

.heading--hero {
  @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
    font-size: 2.25rem;
  }
}

.heading--uppercase {
  font-size: $base-font-size-1;
  margin-bottom: $u-space-small;
  text-transform: uppercase;
}

.heading--overlined {
  border-top: 1px solid $gray-medium;
  padding-top: $u-space-small;
}

.heading--underlined {
  border-bottom: 1px solid $green;
  display: block;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

.heading--underlined--ac {
  border-color: $blue;
}

.caption {
  font-family: $bold-font;
  font-size: $base-font-size-1;
  line-height: 1.3;
}

p {
  line-height: 1.6;
}

.p--body {
  margin-bottom: $u-space-default;
}

.p--hero {
  font-family: $body-font;
  font-size: $base-font-size1;
  margin-top: $u-space-small;

  @media screen and (min-width: $small-desktop-min) {
    font-size: $base-font-size2;
    padding-right: $u-space-small;
  }
}

.p--large {
  font-family: $bold-font;
  font-size: $base-font-size1;
  margin-bottom: $u-space-small;
  line-height: 1.2;

  @media screen and (min-width: $tablet-min) {
    font-size: $base-font-size3;
  }
}

.p--xlarge {
  font-family: $bold-font;
  font-size: $base-font-size2;
  margin-bottom: $u-space-small;
  line-height: 1.2;

  @media screen and (min-width: $tablet-min) {
    font-size: 2rem;
  }
}

.page-title {
  margin-bottom: $u-space-default;
}

strong {
  font-family: $bold-font;
}

a,
.link {
  text-decoration: none;
  color: $link-color;

  .post-list__item & {
    [data-current^="about"] & {
      color: $teal;
    }

    [data-current^="airbeam"] &,
    [data-current^="donate"] & {
      color: $green;
    }
  }

  .p--body &,
  .post-nav & {
    [data-current^="about"] & {
      color: $teal;
    }

    [data-current^="airbeam"] &,
    [data-current^="donate"] & {
      color: $green;
    }
  }
}

.link:hover {
  color: $link-hover-color;
}

.link--light {
  color: $white;

  &:hover {
    color: $white;
  }
}

.link--underlined {
  text-decoration: underline;
}
