.badge-links {
  @include flexbox;
  vertical-align: top;
}

.badge-link {
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  font-family: $bold-font;
  font-size: $base-font-size2;
  height: 10rem;
  line-height: 1.2;
  padding: 0.9rem;
  text-align: center;
  width: 10rem;
  margin: 0.5rem;
  @media screen and (min-width: $tablet-min) {
    height: 13rem;
    width: 13rem;
  }
}

.badge-link--hm,
[data-current^="airbeam"] .badge-link--hm {
  @include button-color($white, $hm-brand, $hm-brand, $white);
}

.badge-link--light-hm,
[data-current^="airbeam"] .badge-link--light-hm {
  @include button-color($hm-brand, $white, $white, none);
}

.badge-link--ac,
[data-current^="airbeam"] .badge-link--ac {
  @include button-color($white, $white, $ac-brand, $white);
}

.badge-link--light-ac,
[data-current^="airbeam"] .badge-link--light-ac {
  @include button-color($ac-brand, $white, $white, none);
}
