.icon-search {
  width: 1.5rem;
  cursor: pointer;
  margin-right: $u-space-tiny;
}

.searchbar-container {
  @include flex(0 0 100%);
  margin-bottom: $u-space-default;
}

.search-container {
  min-height: 40.5vh;
}

// Algolia

.ais-Highlight {
  background: $teal-light;
  font-family: $bold-font;
  font-style: normal;
}

.searchbar-container .ais-search-box--input {
  background: none;
  border: 1px solid $gray;
  border-radius: 3px;
  box-shadow: none;
  -webkit-appearance: none;

  &:focus {
    border: 1px solid $blue;
  }
}
