.posts-index {
  &.posts-index--with-arc {
    background-image: url("/assets/img/svg/Air-Icon_left--teal-light.svg");
    background-repeat: no-repeat;
    background-size: 10rem 10rem;
    background-position: top 10rem left 1vw;

    @media screen and (min-width: $tablet-min) {
      background-size: 19rem 19rem;
      background-position: top 12rem left 2vw;
    }

    @media screen and (min-width: $desktop-min) {
      background-position: top 10rem left 6vw;
    }
  }
}

.post-list {
  max-width: $medium-content;
}

.post-list--grid-view {
  max-width: none;
  width: 100%;

  @media screen and (min-width: $tablet-min) {
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify-content(space-between);
  }
}

.post-list__item {
  margin-bottom: $u-space-default;

  @media screen and (min-width: $tablet-min) {
    .post-list--grid-view & {
      @include flex(0 0 50%);
      padding: 0 $u-space-small;
    }
  }
}

.post-list__thumbnail {
  display: none;

  .post-list--grid-view & {
    display: block;
    margin-bottom: $u-space-small;
    overflow: hidden;
    padding-bottom: 60.5%;
    position: relative;
  }
}

.post-list__thumbnail-img {
  @include animation("fadein 1s forwards");
  position: absolute;
  top: 0;
  left: 0;
}

.post-list__category {
  display: none;

  .post-list--grid-view & {
    display: inline-block;
    margin: 0 0 $u-space-small;
  }
}

a.post-list__category {
  text-decoration: underline;
}

.post-list__read-more {
  display: none;

  .post-list--grid-view & {
    display: inline-block;
    margin-top: $u-space-small;
  }
}

.post-list__title {
  margin-bottom: 1rem;
}
