.footer {
  background-color: $green;
  background-image: url("/assets/img/svg/HabitatMap-Monogram-Footer-White.svg");
  background-position: bottom $u-space-default right $u-space-default,
    bottom right;
  background-repeat: no-repeat;
  color: white;

  @media screen and (min-width: $tablet-min) {
    background-image: url("/assets/img/svg/HabitatMap-Monogram-Footer-White.svg"),
      linear-gradient(to right, $gray-medium, $gray-medium 50%, $green 50%);
  }
}

.footer__nav {
  @include flexbox;
  @include justify-content(space-between);
  @include flex-direction(column);
  background: $gray-medium;

  @media screen and (min-width: $tablet-min) {
    padding-right: $u-space-default;
  }

  @media screen and (max-width: $large-mobile-max) {
    padding: $u-space-default 4vw;
  }

  @media screen and (min-width: $small-desktop-min) {
    @include flex-direction(row);
  }
}

.footer-column {
  @include flex(1);
  border-top: 1px solid $white;
  font-size: $base-font-size-2;
  margin-bottom: $u-space-default;
  vertical-align: top;

  @media screen and (min-width: $small-desktop-min) {
    margin-right: 1.3rem;
  }
}

.footer-column--newsletter {
  margin: 0;

  @media screen and (min-width: $small-desktop-min) {
    @include flex(0 0 35%);
  }
}

.footer-column__heading {
  margin: 1rem 0 2rem;
}

.footer-links {
  font-family: $bold-font;
}

.footer a {
  display: inline-block;
  color: $white;

  &:hover,
  &:focus {
    color: inherit;
    opacity: 0.7;
  }
}

.footer-links__element {
  padding: 0.35rem 0;
}

.footer__address {
  background: $gray-medium;

  @media screen and (max-width: $large-mobile-max) {
    padding: 0 4vw $u-space-default;
  }

  @media screen and (min-width: $tablet-min) {
    margin-top: $u-space-small;
  }
}

.footer__brand {
  @include flexbox;
  @include flex-direction(column);
  @include justify-content(space-between);

  @media screen and (min-width: $tablet-min) {
    padding-left: $u-space-default;
  }

  @media screen and (max-width: $large-mobile-max) {
    padding: $u-space-default 4vw 12rem;
  }

  @media screen and (min-width: $small-desktop-min) {
    padding-bottom: 0;
  }
}

.footer__brand-tagline {
  font-size: $base-font-size2;
  margin: 0 0 $u-space-default;
}
