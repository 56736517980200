.header {
  color: $gray-dark;
  padding: 2rem 0 0.9rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;

  @media screen and (max-width: $tablet-max) {
    padding: 0.5rem 0;

    &.header--nav-expanded {
      height: 100%;
      overflow-y: auto;
      position: fixed;
      width: 100%;
      z-index: 2;
    }
  }

  @media screen and (min-width: $small-desktop-min) {
    &.sticky {
      padding: 1rem 0 0.5rem;
    }
  }
}

.header__banner {
  align-items: center;
  background-color: $cta-color;
  display: flex;
  font-size: $base-font-size-1;
  height: $banner-height;
  justify-content: space-between;
  position: absolute;
  top: 0;
  text-align: center;
  width: 100%;
  padding: 0 1rem;

  @media screen and (min-width: $desktop-min) {
    font-size: $base-font-size;
    padding: 0 $u-space-default;
  }

  @media screen and (max-width: $small-mobile-max) {
    height: $banner-height-mobile;
    line-height: $base-font-size1;
  }

  .icon-nav-close {
    cursor: pointer;
    stroke: $gray-dark;
    vertical-align: middle;
  }
}

.header__save-now-link {
  color: $gray-dark;
  font-weight: bold;
  text-decoration: underline;

  &:hover {
    color: lighten($gray-dark, 10%);
  }
}

.header__menu {
  @include transition(all 0.5s ease);
  font-family: $bold-font;
  font-size: $base-font-size2;

  .icon-nav-close {
    display: none;

    .header--nav-expanded & {
      display: block;
    }
  }

  .header__banner ~ & {
    margin-top: $banner-height;

    @media screen and (max-width: $small-mobile-max) {
      margin-top: $banner-height-mobile;
    }
  }

  @media screen and (min-width: $small-desktop-min) {
    @include flexbox;
    @include justify-content(space-between);
    height: auto;
  }
}

.icon-nav-open {
  .header--nav-expanded & {
    display: none;
  }
}

.header__brand {
  height: 3.5rem;

  @media screen and (max-width: $small-desktop-max) {
    @include flexbox;
    @include justify-content(space-between);
    @include align-items(center);
    @include flex-direction(row-reverse);
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.header__logo {
  padding-left: $u-space-small;

  @media screen and (max-width: $tablet-max) {
    padding-left: 25px; // hamburger icon width
    margin: 0 auto;
  }

  @media screen and (min-width: $desktop-min) {
    height: 3.75rem;
  }

  @media screen and (min-width: $desktop-min) {
    margin-right: 0;
    padding-left: $u-space-default;
    width: 15vw;
  }

  @media screen and (min-width: $large-desktop-min) {
    width: 21vw;
  }
}

.header__button {
  font-size: $base-font-size-2;
  margin-left: $u-space-small;
  text-transform: uppercase;

  [data-current*="buy-now"] & {
    display: none;
  }
}

.header__nav-toggle-button {
  @include appearance(none);
  background: none;

  @media screen and (min-width: $small-desktop-min) {
    margin-left: $u-space-small;
    margin-right: $u-space-small;
    vertical-align: middle;
  }

  @media screen and (min-width: $desktop-min) {
    margin-right: $u-space-default;
  }
}

.desktop-nav-header {
  @include flexbox;
  @include justify-content(space-between);
  padding-bottom: $u-space-small;

  &__close-button {
    @include appearance(none);
    background: none;
  }

  .logo--hm-vertical {
    display: inline-block;
    fill: $white;
    position: relative;
    left: -8px;
  }
}
