.logo {
  display: block;
}

.logo--body {
  height: 40px;
  width: auto;
  margin-bottom: $u-space-small;
}

.logo--airbeam {
  @media screen and (min-width: $tablet-min) {
    height: auto;
  }
}

.logo--aircasting {
  margin-bottom: 17px;
  margin-top: $u-space-default;

  @media screen and (min-width: $tablet-min) {
    height: auto;
    margin-top: 0;
  }
}
