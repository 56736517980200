$header-height: 6 * $base-font-size;
$header-height-mobile: 72px;

body {
  padding-top: $header-height;

  &.js--with-banner {
    padding-top: $header-height + $banner-height;

    @media screen and (max-width: $tablet-max) {
      padding-top: $header-height-mobile + $banner-height;
    }

    @media screen and (max-width: $small-mobile-max) {
      padding-top: $header-height-mobile + $banner-height-mobile;
    }
  }

  @media screen and (max-width: $tablet-max) {
    padding-top: $header-height-mobile;
  }
}

.container--narrow {
  max-width: $narrow-content;
}

.container--centered {
  @media screen and (min-width: $tablet-min) {
    margin: 0 auto;
  }
}
