.ac-intro__button {
  width: 100%;

  @media screen and (min-width: $large-mobile-min) {
    width: 11.25rem;
  }

  & + & {
    margin-top: $u-space-small;

    @media screen and (min-width: $large-mobile-min) {
      margin-left: $u-space-small;
    }
  }
}
