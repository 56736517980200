$input-height: 36px;

.label {
  display: block;
  margin-bottom: 0.5rem;
}

.input {
  background: none;
  border: 1px solid $gray;
  border-radius: 3px;
  box-shadow: none;
  height: $input-height;
  padding: 0 $u-space-tiny;
  -webkit-appearance: none;

  &:focus {
    border: 1px solid $green;
  }
}

.input--light {
  @include placeholder-color($light);
  border-color: $white;
  border-radius: 0;
  margin-top: $u-space-small;

  &:focus {
    border: 1px solid $white;
  }
}

.input--full-width {
  width: 100%;
}

.input--quantity {
  background-image: url("/assets/img/svg/Triangles.svg");
  background-repeat: no-repeat;
  background-position: center right 0.5rem;
  margin-bottom: $u-space-small;
  width: 19rem;
}

.input--submit {
  display: block;
}

.input--currency {
  -moz-appearance: textfield;
  max-width: 19rem;
}

.input--currency::-webkit-outer-spin-button,
.input--currency::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.currency-wrapper {
  margin-bottom: $u-space-default;

  &:after {
    content: "$";
    margin-left: -1.5rem;
  }
}
