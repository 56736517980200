$ac-brand-transparent: transparentize($ac-brand, 0.4);

.button {
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  font-family: $bold-font;
  font-size: $button-font-size;
  height: $button-base-height;
  padding: $button-padding;
  text-align: center;
}

.button--hm {
  @include button-color($hm-brand, $hm-brand, $white, $hm-brand);
}

.button--ac {
  @include button-color($ac-brand, $ac-brand, $white, $ac-brand);

  &[data-current] {
    @include button-color(
      $ac-brand-transparent,
      $ac-brand-transparent,
      $white,
      $ac-brand-transparent
    );
  }
}

.button--ac-about {
  @media screen and (min-width: $tablet-min) {
    margin-top: 1.6rem;
  }
}

.button--ac-on-light-teal {
  @include button-color($ac-brand, $ac-brand, $teal-very-light, $ac-brand);
}

.button--cta {
  text-transform: uppercase;

  @include button-color(
    $text-color: $gray-dark,
    $border-color: transparent,
    $bg-color: $cta-color,
    $bg-color-hover: lighten($cta-color, 15%),
    $text-color-hover: $gray-dark
  );
}

.button--secondary {
  @include button-color($text-color, $text-color, $white, $text-color);
}

.button--small {
  height: 43px;
}
