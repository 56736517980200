.get-consultation {
  @media screen and (min-width: $tablet-min) {
    background-image: url("/assets/img/pages/get-consultation/carson-foreman-cuuHNrzr4mQ-unsplash.jpg?nf_resize=fit&w=1536");
  }

  @media screen and (min-width: $desktop-min) {
    background-image: url("/assets/img/pages/get-consultation/carson-foreman-cuuHNrzr4mQ-unsplash.jpg?nf_resize=fit&w=2050");
  }

  @media screen and (min-width: $large-desktop-min) {
    background-image: url("/assets/img/pages/get-consultation/carson-foreman-cuuHNrzr4mQ-unsplash.jpg");
  }

  &__container {
    margin: 2rem auto;
    padding: 0 2rem;
    width: 40rem;

    @media screen and (min-width: $tablet-min) {
      margin: 5rem auto;
      padding: $u-space-default 4rem;
    }

    @media screen and (min-width: $large-desktop-min) {
      margin: $u-space-huge auto;
    }
  }
}
