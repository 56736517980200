@mixin appearance($value) {
  -webkit-appearance: $value;
  -moz-appearance: $value;
  appearance: $value;
}

@mixin button-color($text-color: $text-color, $border-color: $border-color, $bg-color: $bg-color, $bg-color-hover: $bg-color-hover, $text-color-hover: $bg-color) {
  background: $bg-color;
  border-color: $border-color;
  color: $text-color;

  &:hover,
  &:focus {
    background: $bg-color-hover;
    color: $text-color-hover;
  }
}

@mixin flexbox {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
  -ms-justify-content: $justify;
  justify-content: $justify;
}

@mixin align-items($align) {
  -webkit-align-items: $align;
  -ms-align-items: $align;
  align-items: $align;
}

@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

@mixin placeholder-color($color) {
  &::placeholder {
    color: $color;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: $color;
  }

  &::-ms-input-placeholder {
    color: $color;
  }
}

@mixin transition($transition) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}
