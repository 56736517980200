.post-nav {
  border-top: 1px solid $teal-light;
  display: flex;
  font-family: $bold-font;
  font-size: $base-font-size-1;
  padding: $u-space-default 0;
  width: 100%;

  li {
    flex: 0 0 33.33%;

    &:nth-child(2) {
      text-align: center;
    }

    &:nth-child(3) {
      text-align: right;
    }
  }
}
