.shop-airbeam {
  margin-bottom: $u-space-default;

  .arc-background {
    @media screen and (max-width: $large-mobile-max) {
      background: none;
    }

    @media screen and (min-width: $tablet-min) {
      background-position: bottom 70px right 4vw;
    }

    @media screen and (min-width: $small-desktop-min) {
      background-position: bottom 40px right 4vw;
    }

    @media screen and (min-width: $desktop-min) {
      background-position: bottom 70px right 8vw;
    }

    @media screen and (min-width: $large-desktop-min) {
      background-position: bottom 70px right 16vw;
    }
  }

  &__heading {
    @media screen and (min-width: $tablet-min) {
      font-size: 3rem;
    }
  }

  &__caption {
    font-size: 1.5rem;
    margin: $u-space-small 0 0;

    @media screen and (min-width: $tablet-min) {
      font-size: 2.25rem;
      margin: $u-space-small 0;
    }
  }

  &__action-panel {
    @media screen and (min-width: $tablet-min) {
      padding-bottom: 6rem;
    }

    @media screen and (min-width: $desktop-min) {
      padding-bottom: 10rem;
    }
  }

  &__sub {
    padding-top: 1rem;
    margin: 0 4vw;

    @media screen and (min-width: $tablet-min) {
      margin-left: 10vw;
      margin-right: 10vw;
    }

    @media screen and (min-width: $desktop-min) {
      margin-left: 15vw;
      margin-right: 15vw;
    }

    @media screen and (min-width: $large-desktop-min) {
      margin-left: 21vw;
      margin-right: 21vw;
    }
  }
}

.shop-airbeam-information {
  gap: $u-space-small;

  &__item {
    @include flex(0 0 45%);
  }
}

.order-details-container {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.quantity-input {
  margin: 10px 0;
  max-width: 50px;
}

.cost-info {
  margin: 10px 0;
  width: 100%;
}

.cost-info--calculated {
  font-size: 18px;
  font-weight: 600;
}

.notification-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: fixed;
  margin-top: 10px;
  z-index: 1000;
}

.notification-text {
  padding: 15px 30px;
  border-radius: 3px;
  visibility: hidden;
  width: 70vw;
  position: relative;
}

.notification-text--visible {
  visibility: visible;
}

.notification-text--success {
  background-color: $green-light;
  color: $green;
}

.notification-text--error {
  background-color: $red-light;
  color: $red;
}

.notification-title {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 10px;
}

.notification-close-button {
  padding: 5px;
  cursor: pointer;
  font-size: 34px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  position: absolute;
  right: 10px;
  top: -5px;
  background-color: none;

  &::after {
    content: "\00d7";
  }
}

.get-consult-button {
  font-size: 13px;
  line-height: 25px;
  margin-top: 10px;
  white-space: nowrap;
}

.shop-airbeam__buy-button {
  width: 100%;
}

.shop-airbeam-image {
  width: 100%;
  max-width: 750px;
  height: auto;
  object-fit: cover;
  aspect-ratio: 16 / 9;
}

.shop-airbeam__products {
  padding-bottom: 0px;
  @media screen and (min-width: $tablet-min) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    align-items: start;
  }
}

.shop-airbeam__product {
  padding: 0;

  &:first-of-type {
    padding-bottom: 40px;
  }

  @media screen and (min-width: $tablet-min) {
    &:first-of-type {
      padding-bottom: 0;
    }
  }
}

.product__content {
  padding-top: 20px;

  @media screen and (min-width: $small-desktop-min) {
    display: flex;
    padding: 20px 0;
  }
}

.shop-airbeam__buttons-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;

  @media screen and (min-width: $tablet-min) {
    margin-bottom: 0;
  }
}

.shop-airbeam__description {
  padding-bottom: 20px;

  @media screen and (min-width: $tablet-min) {
    padding-bottom: 40px;
  }
}

.shop-airbeam__comparison-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.shop-airbeam__comparison {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 900px;
}

.shop-airbeam__chart-image {
  min-height: 100px;
  width: 100%;
  max-width: 250px;
  border-radius: 5px;
  display: inline-block;

  @media screen and (min-width: $large-mobile-min) {
    background-size: contain;
    min-height: 120px;
  }

  @media screen and (min-width: $tablet-min) {
    min-height: 150px;
  }
}

.image-abmini {
  background: url("/assets/AirBeamMiniBlur.png") no-repeat center center;
  background-size: cover;
}

.image-ab3 {
  background: url("/assets/img/airbeam-buy-it-now.jpg") no-repeat center center;
  background-size: cover;
}

.shop-airbeam__chart-label {
  font-size: 14px;
  font-weight: 600;

  @media screen and (min-width: $tablet-min) {
    font-size: 18px;
  }
}

table {
  border: 1px solid $teal-light;
  border-radius: 5px;
  border-collapse: separate;
  border-spacing: 0;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  font-size: 14px;

  @media screen and (min-width: $tablet-min) {
    font-size: 16px;
  }

  th,
  td {
    padding: 5px;
    text-align: center;
    vertical-align: middle;

    @media screen and (min-width: $tablet-min) {
      padding: 15px;
    }
  }

  th {
    &:second-child,
    &:last-child {
      width: 35%;
    }

    &:first-child {
      width: 25%;
    }
  }

  thead > tr > th {
    border-right: 1px solid $teal-light;

    &:last-child {
      border-right: none;
    }
  }

  tbody > tr > td {
    border-top: 1px solid $teal-light;
    border-right: 1px solid $teal-light;

    &:first-child {
      font-weight: bolder;
    }

    &:last-child {
      border-right: none;
    }
  }
}

.shop-airbeam__checkmark {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: start;
  background-color: $green;
  margin: 0 auto;

  &::before {
    content: "";
    width: 12px;
    height: 100%;
    background: url("/assets/img/svg/check-solid.svg") no-repeat center;
    background-size: contain;
  }
}

.shop-airbeam__minus {
  border: 2px solid $red-light;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: $white;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  &::before {
    content: "";
    width: 12px;
    height: 100%;
    background: url("/assets/img/svg/minus.svg") no-repeat center;
    background-size: contain;
  }
}

.shop-airbeam__comparison-summary {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  @media screen and (min-width: $tablet-min) {
    margin-top: 40px;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
  }
}

.comparison-summary__list {
  margin-top: 10px;
}

.comparison-summary__list > li {
  list-style-type: disc;
  list-style-position: inside;
  padding-left: 5px;
}
