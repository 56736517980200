.post-view-toggle {
  background: none;
  color: $blue-dark;
  font-family: $bold-font;
  padding: 1rem;

  &:after {
    content: "grid view";
  }

  &.post-view-toggle--grid {
    &:after {
      content: "list view";
    }
  }
}
