.img {
  display: inline-block;
}

.img--margin-top {
  @media screen and (min-width: $tablet-min) {
    margin-top: $u-space-default;
  }
}

.img--alternate-medium {
  max-width: 500px;
}

.img--alternate-small {
  max-width: 380px;
}

.img-full-width {
  background-size: cover;
  background-position: center center;
  padding-bottom: 40%;

  @media screen and (min-width: $large-desktop-min) {
    padding-bottom: 30%;
  }
}

.img-full-width.lazyloaded {
  background-image: url("/assets/img/pages/about-habitatmap/hero.jpg");

  @media screen and (max-width: $desktop-min) {
    background-image: url("/assets/img/pages/about-habitatmap/hero.jpg?nf_resize=fit&w=2050");
  }

  @media screen and (max-width: $small-desktop-max) {
    background-image: url("/assets/img/pages/about-habitatmap/hero.jpg?nf_resize=fit&w=1536");
  }

  @media screen and (max-width: $large-mobile-min) {
    background-image: url("/assets/img/pages/about-habitatmap/hero.jpg?nf_resize=fit&w=720");
  }
}
