// Utilitiy classes to modify any elements

.u--accent-hm {
  color: $hm-brand;
}

.u--accent-ac {
  color: $ac-brand;
}

.u--accent-cyan {
  color: $cyan;
}

.u--align-center {
  text-align: center;
}

.u--align-right {
  @media screen and (min-width: $tablet-min) {
    text-align: right;
  }
}

.u--bg-teal {
  background-color: $teal;
}

.u--bg-teal-very-light {
  background-color: $teal-very-light;
}

.u--bg-teal-light {
  background-color: $teal-light;
}

.u--bg-half-teal-very-light {
  background: linear-gradient(
    to bottom,
    $white,
    $white 50%,
    $teal-very-light 50%
  );
}

.u--bg-cyan {
  background-color: $cyan;
}

.u--bg-blue-dark {
  background-color: $blue-dark;
}

.u--bg-blue-very-dark {
  background-color: $blue-very-dark;
}

.u--bg-green {
  background-color: $green;
}

.u--bold {
  font-family: $bold-font;
}

.u--block {
  display: block;
}

.u--capitalized {
  font-size: $base-font-size-2;
  text-transform: uppercase;
}

.u--full-width {
  width: 100%;
}

.u--gray-text {
  color: $gray;
}

.u--margin-bottom-tiny {
  margin-bottom: $u-space-tiny;
}

.u--margin-top-default {
  margin-top: $u-space-small;

  @media screen and (min-width: $tablet-min) {
    margin-top: $u-space-default;
  }
}

.u--margin-top-big {
  margin-top: $u-space-default;

  @media screen and (min-width: $tablet-min) {
    margin-top: $u-space-big;
  }
}

.u--margin-top-huge {
  margin-top: $u-space-default;

  @media screen and (min-width: $tablet-min) {
    margin-top: $u-space-huge;
  }
}

.u--tiny-text {
  font-size: $base-font-size-2;
}

.u--hidden {
  display: none;
}

.u--visually-hidden {
  position: absolute;
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.u--mobile-hidden {
  @media screen and (max-width: $large-mobile-max) {
    display: none !important;
  }
}

.u--tablet-min-hidden {
  @media screen and (min-width: $tablet-min) {
    display: none !important;
  }
}

.u--tablet-max-hidden {
  @media screen and (max-width: $tablet-max) {
    display: none !important;
  }
}

.u--desktop-hidden {
  @media screen and (min-width: $small-desktop-min) {
    display: none !important;
  }
}

.u--vertical-padding {
  padding-top: $u-space-default;
  padding-bottom: $u-space-default;
}

.u--vertically-centered {
  display: inline-block;
  margin-top: 50%;
  transform: translateY(-50%);
}
