.quote, .citation {
  &__heading {
    margin-bottom: $u-space-small;
  }

  &__body {
    font-family: $quote-font;
    font-size: $base-font-size1;
    line-height: 1.3;

    @media screen and (min-width: $tablet-min) {
      font-size: $base-font-size2;
    }
  }

  &__name {
    margin-top: $u-space-small;
  }
}
