// color
$white: #ffffff;
$light: #f5f5f5;
$teal-very-light: #eff4f4;
$teal-light: #e4eced;
$teal: #33c4b2;
$green: #1ea73f;
$green-light: #bcf3c9;
$red: #843c3c;
$red-light: #f5b1b1;
$cyan: #6abad2;
$blue-light: #7fd8f7;
$blue: #00b2ef;
$blue-dark: #3c70b3;
$blue-very-dark: #1a2679;
$gray: #818181;
$gray-medium: #727272;
$gray-dark: #474c55;
$yellow: #ffe651;

$ac-brand: $blue;
$hm-brand: $green;
$text-color: $gray-medium;
$text-color-negative: $white;
$link-color: $blue;
$link-hover-color: $blue-light;
$cta-color: $yellow;

// viewport breakpoints
$large-desktop-min: 1920px;

$desktop-min: 1366px;

$desktop-mdpi-max: $desktop-min - 1px;
$desktop-mdpi-min: 1280px;

$small-desktop-max: $desktop-min - 1px;
$small-desktop-min: 1025px;

$tablet-max: $small-desktop-min - 1px;
$tablet-min: 768px;

$large-mobile-max: $tablet-min - 1px;
$large-mobile-min: 480px;

$small-mobile-max: $large-mobile-min - 1px;

// spacing
$u-space-tiny: 0.5rem;
$u-space-small: 1.5rem;
$u-space-default: 3rem;
$u-space-big: 6rem;
$u-space-huge: 10rem;

// fonts
$bold-font: "Moderat-Bold", sans-serif;
$body-font: "muli", sans-serif;
$quote-font: "IBM Plex Serif", serif;

// typographic defaults
$base-font-size-2: 0.812rem; // 13px
$base-font-size-1: 0.875rem; // 14px
$base-font-size: 16px;
$base-font-size1: 1.187rem; // 19px
$base-font-size2: 1.5rem; // 24px
$base-font-size3: 1.75rem; // 28px
$base-font-size4: 2.75rem; // 44px

// other
$narrow-content: 18.75rem;
$medium-content: 47rem;

$banner-height: 50px;
$banner-height-mobile: 70px;

$button-base-height: 50px;
$button-font-size: 1.1rem;
$button-padding: 0.8rem 1.2rem;

$nav-font-size-desktop: $base-font-size1;
$subnav-font-size-desktop: $base-font-size;
