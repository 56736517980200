.panel {
  @include flexbox;
  @include flex-direction(column);
  @include justify-content(space-between);
  padding: $u-space-default 4vw;

  @media screen and (min-width: $tablet-min) {
    @include flex-direction(row);
    @include flex-wrap(wrap);
    padding-left: 10vw;
    padding-right: 10vw;
  }

  @media screen and (min-width: $desktop-min) {
    padding-left: 15vw;
    padding-right: 15vw;
  }

  @media screen and (min-width: $large-desktop-min) {
    padding-left: 21vw;
    padding-right: 21vw;
  }
}

.panel--column {
  @include flex-direction(column);
}

.panel--big-padding {
  padding-top: $u-space-big;
  padding-bottom: $u-space-big;
}

.panel--leading-text {
  padding-top: $u-space-small;
  padding-bottom: 0;

  @media screen and (min-width: $small-desktop-min) {
    padding-top: $u-space-default;
    padding-bottom: $u-space-small;
  }
}

.panel--footer {
  @media screen and (max-width: $large-mobile-max) {
    padding: 0;
  }
}

.panel--user-stories {
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 100% auto;
  padding-bottom: 65%;

  &.lazyloaded {
    background-image: url("/assets/img/habitatmap-home-airbeam-aircasting.jpg");

    @media screen and (max-width: $large-mobile-min) {
      background-image: url("/assets/img/habitatmap-home-airbeam-aircasting.jpg?nf_resize=fit&w=720");
    }
  }

  @media screen and (min-width: $tablet-min) {
    background-position: center top;
    background-size: cover;
  }

  @media screen and (min-width: $tablet-min) {
    padding-bottom: 5.5rem;
  }

  @media screen and (min-width: $small-desktop-min) {
    background-position: center right;
    background-size: 50% auto;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }

  @media screen and (min-width: $large-desktop-min) {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
}

.panel--hero {
  &.lazyloaded {
    @media screen and (min-width: $tablet-min) {
      background-image: url("/assets/img/pages/home/airbeam.jpg");
      background-repeat: no-repeat;
      background-position: 50vw center;
      background-size: contain;
    }

    @media screen and (min-width: $tablet-max) {
      background-position-y: top;
    }

    @media screen and (min-width: $desktop-min) {
      padding-bottom: 5rem;
      padding-top: 5rem;
    }

    @media screen and (min-width: 1200px) {
      background-size: 52vw;
    }
  }
}

.panel--banner {
  background-position: center;
  background-size: cover;
  padding-bottom: 11rem;
  padding-top: 4rem;

  &.lazyloaded {
    background-image: url("/assets/img/pages/user-stories/airbeam-userstories-footer-banner.jpg");

    @media screen and (max-width: $large-mobile-min) {
      background-image: url("/assets/img/pages/user-stories/airbeam-userstories-footer-banner.jpg?nf_resize=fit&w=720");
    }
  }
}

.panel--about-intro,
.panel--history-intro,
.panel--faq-intro,
.panel--blog-hero,
.panel--blog-category,
.panel--user-story-hero,
.panel--hero {
  color: $white;

  a {
    color: $white;

    &:hover {
      text-decoration: underline;
    }
  }
}

.panel--faq-intro,
.panel--history-intro,
.panel--blog-category {
  @media screen and (min-width: $tablet-min) {
    padding-top: $u-space-big;
    padding-bottom: $u-space-big;
  }
}

.panel--blog-hero,
.panel--blog-category {
  background-color: $blue;
}

.panel--user-story-hero {
  background-color: $green;
}

.panel--align-center {
  @include align-items(center);
}

.panel--quote,
.panel--testimonial {
  color: $white;
}

.panel--quote {
  @media screen and (min-width: $tablet-min) {
    padding-top: $u-space-big;
    padding-bottom: $u-space-huge;
  }
}

.panel--testimonial {
  @media screen and (min-width: $tablet-min) {
    padding-top: $u-space-big;
    padding-bottom: $u-space-big;
  }
}

.panel--search {
  @media screen and (max-width: $large-mobile-max) {
    padding: 1rem 4vw 0;
  }
}

.panel--faq-intro {
  min-height: 28vh;
}

.panel--history {
  flex-direction: column;

  .p--body {
    max-width: 700px;
  }
}

.panel--buy-now {
  padding-bottom: 0;
  margin-bottom: 0;
}
